    // 生成目录 常规做法
    const catalog = function(element) {
            const nodes = ['H1', 'H2']
            let titles = []
            let number = 0
                // firstChild
            element.childNodes.forEach((e) => {
                if (nodes.includes(e.nodeName)) {
                    let level = e.nodeName.substring(1, 2)
                    let title = e.innerHTML
                    const id = 'hearding-' + number++
                        e.setAttribute('id', id)
                    titles.push({ id: id, title: title, level: level })
                }
            })
            return titles
        }
        // 生成目录，标题中带有 a 标签做法
        // const catalog = function(element) {
        //     const nodes = ['H1', 'H2']
        //     let titles = []
        //         // firstChild
        //     let number = 0
        //     element.childNodes.forEach((e) => {
        //         if (nodes.includes(e.nodeName)) {
        //             let level = e.nodeName.substring(1, 2)
        //             let text = e.innerHTML
        //             let re = /<a id=(.*?)><\/a>\s?(.*)/g
        //             const result = re.exec(text);
        //             const id = 'hearding-' + number++
        //                 e.setAttribute('id', id)
        //             console.log(text, result)
        //             titles.push({ id: id, title: result[2], level: level })
        //         }
        //     })
        //     return titles
        // }

    export {
        catalog
    }