<template>
  <div>
    <el-card>
      <div class="recommend-like">猜你喜欢</div>
      <el-divider></el-divider>
      <div v-for="article in articleList" :key="article.id">
        <div class="recommend-box">
          <router-link
            target="_blank"
            class="recommend-title"
            :to="{name:'articleInfo',params: { articleId: article.id }}"
          >{{article.title}}</router-link>
          <div class="recommend-time">{{article.createTime}}</div>
        </div>
        <div class="recommend-description">{{article.digest}}</div>
        <el-divider></el-divider>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  },
  data() {
    return {
      articleList: []
    };
  },
  mounted() {
    this.getRecommend();
  },
  methods: {
    // 查找推荐文章
    async getRecommend() {
      let param = {
        title: this.title
      };
      const { data: res } = await this.$api.getArticleRecommend(param);
      this.articleList = res.data;
    }
  }
};
</script>


<style lang="less" scoped>
.recommend-like {
  font-weight: 600;
}
.recommend-box {
  display: flex;
  justify-content: space-between;
  .recommend-title {
    text-decoration: none;
    color: inherit;
  }
  .recommend-time {
    font-size: 14px;
  }
}

.recommend-description {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #99b0c7;
}
</style>