<template>
  <div @click="addApprove">
    <span style="display:block;text-align:center">{{approveCount}}</span>
    <el-tooltip class="item" effect="dark" content="喜欢" placement="right">
      <el-avatar ref="approve" alt="喜欢">
        <i class="iconfont">&#xe73c;</i>
      </el-avatar>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    username: {
      type: String
    },
    articleId: {
      type: String
    }
  },
  data() {
    return {
      approveCount: 0
    };
  },
  watch:{
    articleId(){
      // console.log(555,this.articleId)
      this.getArticleApproveCount()
    }
  },
  mounted() {
    this.getArticleApproveCount();
  },
  methods: {
    // 获取文章赞同
    async getArticleApproveCount() {
      const { data: res } = await this.$api.getArticleApproveCount(
        this.articleId
      );
      this.approveCount = res.data;
    },
    // 添加文章赞同
    async addApprove() {
      if (!this.username) {
        this.$alert("只有登陆用户才可以点赞哟", "提示", {
          confirmButtonText: "确定",
          callback: action => {}
        });
        return;
      }
      const { data: res } = await this.$api.getArticleApproveCount(
        this.articleId,
        { username: this.username }
      );
      if (res.data != 0) {
        this.$alert("您已经赞过该文章，请不要重复点赞", "提示", {
          confirmButtonText: "确定",
          callback: action => {}
        });
        return;
      }
      // 点赞
      let params = {
        username: this.username,
        articleId: this.articleId
      };
      const { data: result } = await this.$api.addArticleApprove(params);
      this.getArticleApproveCount();
    }
  }
};
</script>