var hljs = require('highlight.js')

var markdown_config = {
    html: true, // Enable HTML tags in source
    xhtmlOut: true, // Use '/' to close single tags (<br />).
    breaks: true, // Convert '\n' in paragraphs into <br>
    langPrefix: 'lang-', // CSS language prefix for fenced blocks. Can be
    linkify: false, // 自动识别url
    typographer: true,
    quotes: '“”‘’',
    highlight: function(str, lang) {
        const codeIndex = parseInt(Date.now()) + Math.floor(Math.random() * 10000000)
        if (lang && hljs.getLanguage(lang)) {
            return `<pre class="hljs"><span cursor:pointer class="copy-btn" data-clipboard-target="#code${codeIndex}">复制代码</span><code id=code${codeIndex} >${hljs.highlight(lang, str, true).value}</code></pre>`;
        }

        return `<span cursor:pointer class="copy-btn" data-clipboard-target="#code${codeIndex}">复制代码</span><code id=code${codeIndex}>${markdown.utils.escapeHtml(str)}</code>`;
    }
}

var markdown = require("markdown-it")(markdown_config);

// 表情
var emoji = require('markdown-it-emoji');
// 下标
var sub = require('markdown-it-sub')
    // 上标
var sup = require('markdown-it-sup')
    // <dl/>
var deflist = require('markdown-it-deflist')
    // <abbr/>
var abbr = require('markdown-it-abbr')
    // footnote
var footnote = require('markdown-it-footnote')
    // insert 带有下划线 样式 ++ ++
var insert = require('markdown-it-ins')
    // mark
var mark = require('markdown-it-mark')
    // taskLists
var taskLists = require('markdown-it-task-lists')
    // container
var container = require('markdown-it-container')
    // 目录
    // var toc = require('markdown-it-toc')

// var mihe = require('markdown-it-highlightjs-external');
// math katex
var katex = require('markdown-it-katex-external');
var miip = require('markdown-it-images-preview');

markdown
    .use(emoji)
    .use(sup)
    .use(sub)
    .use(container)
    .use(container, 'hljs-left') /* align left */
    .use(container, 'hljs-center') /* align center */
    .use(container, 'hljs-right') /* align right */
    .use(deflist)
    .use(abbr)
    .use(footnote)
    .use(insert)
    .use(mark)
    .use(container)
    .use(miip)
    .use(katex)
    .use(taskLists)
    // .use(toc)

export {
    markdown
}