<template>
  <div>
    <el-dialog
      title="关注知识追寻者官方公众号"
      :visible.sync="followDialogVisible"
      width="480px"
      @close="followDialogClosed"
    >
      <img
        style="width: 160px; height: 160px;margin-left:100px"
        fit="cover"
        src="@/assets/img/zszxz.jpg"
        class="profile"
      />
      <el-form :model="follow" ref="followRef" size="mini" label-width="100px">
        <el-form-item label="解锁密码" prop="value">
          <el-input v-model="follow.value" placeholder="微信扫公众号二维码，后台回复 98k 获取解锁密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="followDialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="validSecret()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
  props: {
      article: {
      type: Object
    }
  },
  data() {
    return {
        follow:{},
        followDialogVisible: false,
        address: "https://zszxz.com" + this.$route.path,
        //lockSecret: '13love14'
    };
  },
  mounted() {
    this.copyCode();
    this.copyArtile();
  },
  methods: {
    copyCode() {
      // 官网 https://clipboardjs.com/
      let clipboard = new Clipboard(".copy-btn");
    },
    copyArtile() {
      let content = document
      // 掘金这里不是全局监听，应该只是监听文章的dom范围内。
      content.oncopy = event => {
        event.preventDefault(); // 取消默认的复制事件
        let textFont,
          copyFont = window.getSelection(0).toString(); // 被复制的文字 等下插入
        // 防知乎掘金 复制一两个字则不添加版权信息 超过一定长度的文字 就添加版权信息
        if (copyFont.length > 200) {
          textFont =
            copyFont +
            `\n作者：${this.article.name}\n链接：${this.address}\n来源：知识追寻者\n版权:著作权归作者所有。商业转载请联系作者获得授权，非商业转载请注明出处。`;
        } else {
          textFont = copyFont; // 没超过十个字 则采用被复制的内容。
        }
        let secret = localStorage.getItem("zs98");
        if (!secret || secret !== window.lock) {
          this.followDialogVisible = true;
          return;
        }
        if (event.clipboardData) {
          return event.clipboardData.setData("text", textFont); // 将信息写入粘贴板
        } else {
          // 兼容IE
          return window.clipboardData.setData("text", textFont);
        }
      };
    },
    // 复制文章解锁校验
    validSecret() {
      if (this.follow.value !== window.lock) {
        this.$message.error("解锁密码错误,请获取正确密码后输入");
        return;
      }
      localStorage.setItem("zs98", this.follow.value);
      this.followDialogVisible = false;
    },
     followDialogClosed() {
      this.follow = {};
    },
  }
};
</script>