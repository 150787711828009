<template>
  <div>
      <el-row>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="既然来了，就留下点东西吧！"
          v-model="value"
          maxlength="500"
          show-word-limit
        ></el-input>
      </el-row>
      <el-row style="margin:5px 0px">
        <el-button size="mini" @click="handleSubmit" style="float:right;display:block" type="primary" :disabled="disabled">添加评论</el-button>
      </el-row>
    </div>
</template>
<script>
export default {
  name: 'commentBox',
  props: {
    parent: {
      type: Object
    },
    clearCach: {
      type: Number
    }
  },
  data() {
    return {
      value: '',
      disabled: true
    };
  },
  watch:{
    clearCach:function(val){
      this.value = ''
    },
    value:function(val){
      if(val!=null && val.replace(/(^s*)|(s*$)/g, "").length !=0){
        this.disabled = false;
      }else{
        this.disabled = true;
      }
    }
  },
  methods: {
    handleSubmit() {
      let username = this.$store.state.permission.user.username
      if (!username) {
        this.$alert("没登陆还想评论呀，放过我吧！哼白嫖党！", "提示", {
          confirmButtonText: "确定",
          callback: action => {
          }
        })
        return
      }
      let comments = {
        articleId: this.parent.articleId,
        parentId: this.parent.id,
        username: username,
        content: this.value,
      };
      if(username == this.parent.username){
        this.$message({ type: "error", message: "自言自语没意思啦" });
        return
      }
      this.$emit("submit-box", comments)
      this.value = ''
    }
  }
};
</script>
