<template>
  <div >
    <div @click="showDialog" style="margin-top:10px">
      <el-tooltip class="item" effect="dark" content="举报" placement="right">
        <el-avatar ref="remark" icon="el-icon-warning-outline" alt="举报"></el-avatar>
      </el-tooltip>
    </div>
    <!-- 举报对话框 -->
    <el-dialog title="举报该文章"  :visible.sync="addDialogVisible" width="30%" :modal-append-to-body="false" @close="addDialogClosed">
      <el-form :model="accuse" ref="accuseRef" size="mini" label-width="100px">
        <el-form-item label="举报类型" prop="accuseType">
          <el-select size="mini" v-model="accuse.accuseType" clearable placeholder="请选择举报类型">
            <el-option
              v-for="item in accuseTypeList"
              :key="item.id"
              :label="item.typeName"
              :value="''+item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="举报内容" prop="accuseConent">
          <el-input
            type="textarea"
            :rows="4"
            maxlength="200"
            show-word-limit
            v-model="accuse.accuseConent"
            placeholder="请提供证明内容，不得胡乱举报，浪费公共资源，否则给予账号一定处理"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="addDialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="addAccuse()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    username: {
      type: String
    },
    article: {
      type: Object
    }
  },
  data() {
    return {
      addDialogVisible: false,
      accuse: {},
      accuseTypeList: []
    };
  },
  mounted() {},
  methods: {
    // 显示举报对话框
    showDialog() {
      if (!this.username) {
        this.$alert("只有登陆用户才可以举报", "提示", {
          confirmButtonText: "确定",
          callback: action => {}
        });
        return;
      }
      this.getArticleAccuseType();
      this.addDialogVisible = true;
    },
    // 举报
    addDialogClosed() {
      this.accuse = {};
    },
    async getArticleAccuseType() {
      const { data: res } = await this.$api.getArticleAccuseType();
      this.accuseTypeList = res.data;
    },
    async addAccuse() {
      if (!this.accuse.accuseConent || !this.accuse.accuseType) {
        this.$message.error("请输入举报内容和举报类型");
        return;
      }
      this.accuse.accuseAccount = this.article.author;
      this.accuse.username = this.username;
      this.accuse.articleId = this.article.id;
      this.accuse.title = this.article.title;
      this.accuse.tip =
        "您收到一条举报信息，请确认是否违规，如果违规请马上处理；如果等到审核人员确认违规会对您的账号进行一定的处罚;若未违规，请忽略该信息";
      const { data: res } = await this.$api.addArticleAccuse(this.accuse);
      this.addDialogVisible = false;
      this.$alert(
        "感谢您的举报，为本站做出贡献；我们会在7个工作日内进行审核，做出相应处理。",
        "提示",
        {
          confirmButtonText: "确定",
          callback: action => {}
        }
      );
    }
  }
};
</script>

<style scoped>

</style>