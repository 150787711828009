<template>
  <div style="width:100%">
    <div class="comment-box" v-for="item in comments" :key="item.id">
      <div class="img-content">
        <div class="comment-img">
          <el-avatar :size="50" :src="item.icon" :alt="item.name"></el-avatar>
        </div>
        <div class="comment-content" :span="20">
          <a>{{item.name}}</a>
          <p>{{item.visible==true?item.content:'该评论已被丢弃'}}</p>
        </div>
      </div>
      <div class="comment-reply">
         <a
         href="#"
          v-if="owner"
          style="margin-right:5px;display:block;text-decoration: none;color:red;"
          class="el-icon-delete"
          @click="editArticleRemarkVisiable(item.id,item.visible)"
        >屏蔽</a>
        <a
          href="#"
          style=" text-decoration: none;;display:block;"
          class="el-icon-chat-dot-round"
          @click="replyTo(item.id)"
        >回复</a>
      </div>
      <commentBox
        class="comment"
        :ref="`comment${item.id}`"
        style="display:none"
        :parent="item"
        :clearCach="clearCach"
        @submit-box="submitBox"
      ></commentBox>
      <el-divider content-position="left"></el-divider>
      <!-- 递归调用 -->
      <commentList :comments="item.children" :owner="owner"></commentList>
    </div>
  </div>
</template>
<script>
import commentBox from "./commentBox";
export default {
  name: "commentList",
  props: ["owner","comments"],
  data() {
    return {
      clearCach: 0,
      visiable: 0,
      id:'',
    };
  },
  components: {
    commentBox
  },
  watch: {
    visiable:function(val){
      this.$emit("update-remark")
    }
  },
  methods: {
    replyTo: function(id) {
      this.$nextTick(() => {
        // 清除缓存
        if(this.clearCach==0){
          this.clearCach =1
        }else{
          this.clearCach =0
        }
        // 双击回复时是否隐藏输入框
        let diplay = this.$refs[`comment${id}`][0].$el.style.display;
        if (diplay == "none") {
          this.$refs[`comment${id}`][0].$el.style.display = "block";
        } else {
          this.$refs[`comment${id}`][0].$el.style.display = "none";
        }
        this.id = id
      });
    },
    // 添加评论
    async submitBox(msg) {
      const { data: res } = await this.$api.addArticleRemark(msg);
      let children =  res.data
      this.setCommnetChildren(children)
      this.replyTo(this.id)
    },
    // 递归设置节点，vue自动渲染
    setCommnetChildren(data){
      if(data.length<=0){
        return
      }
      this.comments.forEach(element => {
        if(element.id == data.parentId){
          element.children.push(data)
          return
        }else {
          setCommnetChildren(element.children)
        }
      });
    },
    // 将评论折叠
    async editArticleRemarkVisiable(id, visible){
      if(visible==false){
         this.$alert("该评论已经被屏蔽，请勿重复点击", "提示", {
          confirmButtonText: "确定",
          callback: action => {
          }
        })
        return
      }
      const { data: res } = await this.$api.editArticleRemarkVisiable(id);
      if(this.visiable == 0){
        this.visiable = 1
      }else{
        this.visiable = 0
      }
    }
  }
};
</script>
<style scoped>
@import '../../assets/css/commentList.css'
</style>